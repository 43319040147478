<template>
    <div class="container">
        <div class="play-box">
            <div class="play-video">
                <video ref="videoElement" :src="src" @timeupdate="handleTimeUpdate" @loadedmetadata="handleMetadataloaded"></video>
            </div>
        </div>
        <div class="controls-box">
            <div class="play-btns">
                <div class="play-btn play" v-if="!status" @click="playing">
                    <i class="el-icon-video-play"></i>
                </div>
                <div class="play-btn pause" v-else @click="pause">
                    <i class="el-icon-video-pause"></i>
                </div>
            </div>
            <div class="play-proccess">
                <el-slider :max="maxValue" v-model="process" @change="changePlay"></el-slider>
            </div>
        </div>
<!--        <el-slider v-model="process" :max="34" @change="changePlay"></el-slider>-->
<!--        <el-button @click="playing">play</el-button>-->
<!--        <el-button @click="pause">暂停</el-button>-->
    </div>

</template>

<script>
export default {
    data() {
        return {
            src: "",
            video: null,
            process: 0,
            status: 0,
            maxValue: Number(this.$route.query.duration),
        }
    },
    mounted() {
        this.video = this.$refs.videoElement
        const url = this.$route.query.url
        this.src = url
        this.video.currentTime = 1
        //监听加载进度
        // this.video.addEventListener("loadedmetadata", this.handleMetadataloaded)
    },
    methods: {
        playing() {
            this.video.play()
            this.status = 1
        },
        handleTimeUpdate() {
            this.handleMetadataloaded()
        },
        pause() {
            this.video.pause()
            this.status = 0
        },
        handleMetadataloaded() {
            this.process = this.$refs.videoElement.currentTime
            if (this.process > this.maxValue) {
                this.status = 0
            }
        },
        changePlay() {
            if (this.video.paused) {
                this.video.pause()
                this.video.currentTime = this.process
            } else {
                // this.$refs.videoElement.pause()
                this.video.pause()
                this.video.currentTime = this.process
                setTimeout(() => {
                    this.video.play()
                }, 500)
                // this.$refs.videoElement.play()
            }
        },
    }
}

</script>
<style scoped lang="scss">
.container {
    & * {
        box-sizing: border-box;
    }
    width: 100%;
    height: calc(100vh - 60px);
    .play-box {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        position: relative;
        .play-video {
            width: calc(30% - 20px);
            overflow: hidden;
            height: 100%;
            border-radius: 10px;
            display: flex;
            align-items: flex-end;
            //transform: translateY(3%);
            video {
                width: calc(100% / 0.3 - 20px);
                transform: translate(-35%);
            }
        }

    }
    .controls-box {
        padding: 0 20px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .play-btns {
            width: 80px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            .play-btn {
                cursor: pointer;
                i {
                    font-size: 25px;
                    color: #409EFF;

                }
                &.pause {
                    i {
                      color: #909399;
                    }
                }
            }
        }
        .play-proccess {
            flex: 1;
        }
    }
}

</style>